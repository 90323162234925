@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0 0 20px 0;
  padding: 0;
  background-color: whitesmoke;
}

* {
  font-family: "Inter", sans-serif !important;
}
